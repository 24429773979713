import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/nasa.png";
import projImg2 from "../assets/img/tender.png";
import projImg4 from "../assets/img/order-tracker.png";
import projImg5 from "../assets/img/shoplyft-admin.png";
import projImg6 from "../assets/img/shoplyft-customer.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "Nasa Mission Control",
      description: "Tech Stack(React, Node/express, mongoDB)",
      imgUrl: projImg1,
      link: "https://nasa-project-frontend.onrender.com/"
    },
    {
      title: "Tender Bidding",
      description: "Tech Stack(React, Redux, Node/express, PostgreSQL)",
      imgUrl: projImg2,
      link: "https://tender-bid.onrender.com/"
    },
    {
      title: "Shoplyft",
      description: "Tech Stack(React, Redux, Node/express, mongoDB)",
      imgUrl: projImg6,
      link: "https://shop-lyft-customers.onrender.com/"
    },
    {
      title: "Order Tracker",
      description: "Tech Stack(React, Redux, Node/express, PostgreSQL)",
      imgUrl: projImg4,
      link: "https://order-tracker-client.onrender.com/"
    },
    {
      title: "Shoplyft Admin",
      description: "Tech Stack(React, Redux, Node/express, mongoDB)",
      imgUrl: projImg5,
      link: "https://shop-lyft-admin.netlify.app/"
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Projects</h2>
                  <p>
                  Below, you'll find a showcase of some of my latest projects. Each project represents a unique journey of problem-solving, creativity, and passion for technology. Dive in to explore the diverse range of applications, websites, and tools I've developed. Whether it's crafting intuitive user interfaces, architecting robust backend systems, or optimizing performance, each project reflects my commitment to delivering high-quality solutions. Feel free to click on any project to learn more about its features, technologies used, and the challenges overcome. Let's embark on this journey together through the world of software development!
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Projects</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Contact Me</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Blogs</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="section">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Cumque quam, quod neque provident velit, rem
                          explicabo excepturi id illo molestiae blanditiis,
                          eligendi dicta officiis asperiores delectus quasi
                          inventore debitis quo.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>
                          <a href="https://medium.com/@ogheneruonaagadagba4/acid-principles-in-database-3b4788a081b9" target="_blank" rel="noopener noreferrer">ACID principles in database.</a>
                        </p>
                        <p>
                        <a href="https://medium.com/@ogheneruonaagadagba4/how-javascript-works-9d0263f2bb56" target="_blank" rel="noopener noreferrer">How Javascript works</a>
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
